<template>
  <div class="callsTable mt-4">
    <h1 class="main-color">
      {{ $t("Students QR Codes") }}
    </h1>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t("Class Name") }}</th>
            <th class="text-left">{{ $t("Download") }}</th>
          </tr>
        </thead>
        <tbody v-if="items.length > 0">
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.class_name }}</td>
            <td>
              <v-btn
                class="main-btn"
                @click="getDownloadLink(item.id, index)"
                :loading="loading[index] ? true : false"
                >{{ $t("Download PDF") }}</v-btn
              >
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3">
              <p class="text-center">{{ $t("There is no data available") }}</p>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "studentsQrCode",
  data() {
    return {
      loading: [],
      items: [],
      className: "",
      classId: "",
    };
  },
  methods: {
    getAllClassesQR() {
      this.$http
        .get(this.getApiUrl + "/nedaa/getClassList", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.items = response.data.data;

            this.items.forEach(() => {
              this.loading.push(false);
            });
          }
        });
    },

    getDownloadLink(id, index) {
      this.$set(this.loading, index, true);
      this.$http
        .get(this.getApiUrl + "/nedaa/printQrCodeStudentClassList/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.$set(this.loading, index, false);
          if (response.data.status.error == false) {
            const link = response.data.data;
            window.open(link, "_blank");
          }
        });
    },
  },
  mounted() {
    this.getAllClassesQR();
  },
};
</script>

<style></style>
